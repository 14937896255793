import React, {useState, useEffect} from 'react';


import { BrowserRouter, Routes, Route, useLocation, HashRouter, MemoryRouter } from "react-router-dom";

import { Main } from './app/containers';
import Common from './Common';
import { useSelector, useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';

//import jwt from 'expo-jwt';
//import ProgressStateBar from './app/components/_commons/ProgressStateBar';
import { setUserData, setUserToken } from './app/modules/user';

import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './resources/assets/styles/sass/Toast.scss';


function App() {

    // console.log = function() {}

    const dispatch = useDispatch();

    const loadingAuth = useSelector(state => state.loading.loadingAuth);

    const cookies = new Cookies();
    /*
    var userAuthToken;

    if(cookies.get('token') != undefined) {
      var userData = [];
      for (const [key, value] of Object.entries(cookies.get('user_data'))) {
        userData[value[0]] = value[1];
      }
      userAuthToken = true;
      dispatch(setUserToken( cookies.get('token') ))
      dispatch( setUserData(userData) );
      // 사용자 정보 및 토큰 스토어에 저장
    } else {
      userAuthToken = false;
    }
    */

  return (
    <>
      <Common/>
      <ToastContainer />
    </>
  )

}

export default App; 