

import React from "react";

export const Version = ( ) => {



  return (
    <>
        V.{APP_VERSION}    
    </>
  );
}

export default Version;