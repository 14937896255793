
import React, {useState, useEffect, useRef} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setAiChatSelectedSpaceChatHistory } from '../../modules/aiChat';
import { setLoadingWork } from '../../modules/loading';
import { setSummaryDocSelectedHistory } from "../../modules/summaryDoc";

const SocketController = () => {

    const dispatch = useDispatch();

    const [socketData, setSocketData] = useState(false);

    const userToken = useSelector(state => state.user.userToken);
    const orgCode = useSelector(state => state.organization.orgCode);
    const gptChatCurrentChatHistory = useSelector(state => state.apps.gptChatCurrentChatHistory);
    const aiChatSelectedSpace = useSelector(state => state.aiChat.aiChatSelectedSpace);    
    const aiChatSelectedSpaceChatHistory = useSelector(state => state.aiChat.aiChatSelectedSpaceChatHistory);

    const summaryDocSelectedSpace = useSelector(state => state.summaryDoc.summaryDocSelectedSpace);
    const summaryDocSelectedSpaceChatHistory = useSelector(state => state.summaryDoc.summaryDocSelectedSpaceChatHistory);

    const selectedApp = useSelector(state => state.status.selectedApp);

    let ws = useRef(null);

    useEffect(() => {

        
        if(socketData != false){
            //var tmpGptChat = aiChatSelectedSpaceChatHistory;

            //tmpGptChat[tmpGptChat.length-1]["message"] = tmpGptChat[tmpGptChat.length-1]["message"] + socketData.content;

            //console.log('init socketData', socketData);
            //console.log("selectedApp : ", selectedApp);
            var startString = 0;



            switch(selectedApp){
                case "SummaryDoc":
                    if(socketData['type'] == "summaryDoc" && socketData['spaceId'] == summaryDocSelectedSpace.space_id){

                        var tmpSummaryDocSelectedSpaceChatHistory = summaryDocSelectedSpaceChatHistory;
                        switch(socketData['state']){
                            case "start":
                                tmpSummaryDocSelectedSpaceChatHistory.push({
                                    "content" : socketData['content'],
                                    "role" : socketData['role'],
                                    "msgId" : socketData['userMsgId']
                                })
                                
                                tmpSummaryDocSelectedSpaceChatHistory = [...tmpSummaryDocSelectedSpaceChatHistory]
                                
                                dispatch(setSummaryDocSelectedHistory(tmpSummaryDocSelectedSpaceChatHistory))
                            break;
                            case "streaming":
                                
                                const entry = Object.entries(tmpSummaryDocSelectedSpaceChatHistory).find(([key, value]) => value.msgId === socketData['assistantMsgId']);
                                if (entry) {
                                    const [key, value] = entry;
                                    value.content = value.content + socketData['content'];
                                    tmpSummaryDocSelectedSpaceChatHistory = [...tmpSummaryDocSelectedSpaceChatHistory]
                                    
                                    dispatch(setSummaryDocSelectedHistory(tmpSummaryDocSelectedSpaceChatHistory))                                  
                                    console.log("content가 성공적으로 수정되었습니다. : ", value);
                                } else {
                                    console.log("해당 msgId를 가진 데이터를 찾을 수 없습니다.");
                                    tmpSummaryDocSelectedSpaceChatHistory.push({
                                        "content" : socketData['content'],
                                        "role" : socketData['role'],
                                        "msgId" : socketData['assistantMsgId']
                                    })
                                    
                                    tmpSummaryDocSelectedSpaceChatHistory = [...tmpSummaryDocSelectedSpaceChatHistory]
                                    
                                    dispatch(setSummaryDocSelectedHistory(tmpSummaryDocSelectedSpaceChatHistory))  
                                }
                            break;
                            case "end":
                                dispatch(setLoadingWork(false))
    
                            break;                            
    
                        }

                    }


                 
                break;
                case "CHAT":

                    if(socketData['type'] == "aiChat" && socketData['spaceId'] == aiChatSelectedSpace.space_id){                
                        console.log("CURRENT SPACEID : ", aiChatSelectedSpace.space_id);
                        var tmpHistory = aiChatSelectedSpaceChatHistory;
                        switch(socketData['state']){
                            case "start":
                                tmpHistory.push({
                                    "message" : socketData['content'],
                                    "from" : socketData['role'],
                                    "msgId" : socketData['userMsgId']
                                })
                                
                                tmpHistory = [...tmpHistory]
                                
                                dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))
                            break;
                            case "streaming":
                                
                                const entry = Object.entries(tmpHistory).find(([key, value]) => value.msgId === socketData['assistantMsgId']);
                                if (entry) {
                                    const [key, value] = entry;
                                    value.message = socketData['content'];
                                    tmpHistory = [...tmpHistory]
                                    
                                    dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))                                  
                                    console.log("content가 성공적으로 수정되었습니다. : ", value);
                                } else {
                                    console.log("해당 msgId를 가진 데이터를 찾을 수 없습니다.");
                                    tmpHistory.push({
                                        "message" : socketData['content'],
                                        "from" : socketData['role'],
                                        "msgId" : socketData['assistantMsgId']
                                    })
                                    
                                    tmpHistory = [...tmpHistory]
                                    
                                    dispatch(setAiChatSelectedSpaceChatHistory(tmpHistory))  
                                }
                            break;
                            case "end":
                                dispatch(setLoadingWork(false))
                                console.log("Message ENDED : ", socketData);

                            break;                            

                        }
                    }

                break;
            }
            /*
            var Interval = setInterval(function(){

                var string = socketData.content.substr(startString,1 )
                console.log(string)
                console.log("aiChatSelectedSpaceChatHistory tmpGptChat : ", tmpGptChat);

                if(tmpGptChat.length != 0) {
                    if(typeof tmpGptChat[tmpGptChat.length-1]["message"] != 'undefined') {
                        tmpGptChat[tmpGptChat.length-1]["message"] = tmpGptChat[tmpGptChat.length-1]["message"] + string;
                        //tmpGptChat.push(array)
                        tmpGptChat = [...tmpGptChat]
                        
                        console.log("socketData.content : ",socketData.content)
                        console.log("socketData.content : ",string)
            
                        //tmpGptChat[tmpGptChat.length+1] = array;
                        startString++;
                        dispatch(setAiChatSelectedSpaceChatHistory(tmpGptChat));
                    }
                }
                
                
                if(socketData.content.length == startString){
                    clearInterval(Interval)
                    setSocketData(false)
                }

            }, 100);
            */
           /*
            return () => {
                //clearInterval(Interval);
                var tmpGptChat = aiChatSelectedSpaceChatHistory;
                
                console.log("startString : ", startString);
                
                //if(tmpGptChat[tmpGptChat.length-1]["message"] != socketData.content){
                    var string = socketData.content.substring(startString)
                    
                    if(tmpGptChat.length != 0) {
                        if(typeof tmpGptChat[tmpGptChat.length-1]["message"] != 'undefined') {
                            tmpGptChat[tmpGptChat.length-1]["messadge"] = tmpGptChat[tmpGptChat.length-1]["message"] + string;
                            dispatch(setAiChatSelectedSpaceChatHistory(tmpGptChat));
                        }
                    }
                //}
            };
            */
        }

        if(socketData == false) {

            // 답변 중 disabled 조건 수정 예정
            setTimeout(() => { // 채팅방 클릭 / 답변 완료 후 타입아웃 추가
                dispatch(setLoadingWork(false));
            }, 1000);
            // 채팅 답변 완료 후 loading값 변경하여 추가 채팅 메세지 입력할 수 있도록 변경
        }

    },[socketData]);  

    useEffect(() => {
        if(userToken !== false && orgCode !== false){
            
            if (!ws.current) {
                var webSocketUrl = SOCKET_URL + "/" + SOCKET_VERSION + "?orgCode=" + encodeURIComponent(orgCode) + "&token=" + encodeURIComponent(userToken);

                ws.current = new WebSocket(webSocketUrl);
                ws.current.onopen = (socket) => {

                    console.log("connected to " + webSocketUrl);
                    console.log("ws.current : ", socket);

                    setInterval(function(){
                        var reqData = {
                        "message" : "ping",
                        //"connectionId" : 
                        }
                        ws.current.send( JSON.stringify(reqData) );
                    }, 300000)

                };

                ws.current.onclose = (error) => {
                    console.log("disconnect from " + webSocketUrl);
                    console.log(error);
                };

                ws.current.onerror = (error) => {
                    console.log("connection error " + webSocketUrl);
                    console.log(error);
                };

                ws.current.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    
                    //var tmpGptChat = gptChatCurrentChatHistory;
                    if(data.response != "pong"){

                        console.log(data);
                        console.log("aiChatSelectedSpaceChatHistory : ", aiChatSelectedSpaceChatHistory)
                        setSocketData(data);
                        /*
                        var tmpGptChat = aiChatSelectedSpaceChatHistory;
                        console.log("tmpGptChat count : ",tmpGptChat.length);
                        //tmpGptChat[tmpGptChat.length-1]["message"] = tmpGptChat[tmpGptChat.length-1]["message"] + data.content;

                        console.log("gptChatCurrentChatHistory tmpGptChat : ", tmpGptChat);
                        
                        //tmpGptChat.push(array)
                        tmpGptChat = [...tmpGptChat]
                        
                        //tmpGptChat[tmpGptChat.length+1] = array;
                        
                        dispatch(setAiChatSelectedSpaceChatHistory(tmpGptChat));
                        */
                    }
                };
            }

            return () => {
                console.log("clean up");
                ws.current.close();
            };

        }

    },[userToken, orgCode]);  
    
    return(
      <></>



    )

}


export default SocketController;